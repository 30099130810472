import React from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Surface from './Surface';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 20px',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  goBackIcon: {
    marginRight: 10,
  },
}));

const SectionHeader = ({ title, withBackButton = false, onBack, children }) => {
  const history = useHistory();
  const classes = useStyles();

  const handleBack = () => {
    if (onBack) onBack();
    else history.goBack();
  };

  return (
    <Surface className={classes.paper}>
      <div className={classes.flex}>
        {withBackButton && (
          <IconButton onClick={handleBack} size="small" className={classes.goBackIcon}>
            <ArrowBack />
          </IconButton>
        )}
        <Typography className={classes.title}>{title}</Typography>
      </div>
      {children}
    </Surface>
  );
};

export default SectionHeader;
