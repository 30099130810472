import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, CssBaseline, Hidden } from '@material-ui/core';
import { getCookie } from '../../../utils/utils';
import DrawerContent from './DrawerContent';
import { drawerOptions } from './options';
import { useHistory } from 'react-router-dom';

const DRAWER_WIDTH = 250;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      overflow: 'hidden',
    },
  },
  container: {
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: '#EDEDED',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
    },
  },
  drawerPaper: {
    zIndex: 1000,
    width: DRAWER_WIDTH,
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
}));

const DrawerLeft = ({ mobileOpen, handleDrawerToggle, logout }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [smallScreen, setSmallScreen] = useState(false);
  const [routeOptions, setRouteOptions] = useState([]);
  const getOption = useCallback(
    () => drawerOptions.find(option => history.location.pathname.includes(option.path)),
    [history.location.pathname]
  );
  const [value, setValue] = useState(getOption()?.value || 0);

  const filteredDrawerOptions = drawerOptions.filter(op =>
    routeOptions.some(r_op => r_op.value === op.value)
  );

  const handleChange = index => {
    setValue(index);
    handleDrawerToggle(false);
  };

  const focusItem = valor => {
    if (value === valor)
      return {
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        marginTop: 10,
        paddingLeft: 12,
      };
    else return { color: 'grey', marginTop: 10 };
  };

  useEffect(() => {
    setValue(getOption()?.value || 0);
  }, [history.location.pathname, getOption]);

  useEffect(() => {
    const resize = () => setSmallScreen(window.innerHeight <= 735);
    window.addEventListener('resize', resize);
    setRouteOptions(JSON.parse(getCookie('routeOptions')));
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={() => handleDrawerToggle()}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerContent
            handleChange={handleChange}
            focusItem={focusItem}
            value={value}
            filteredDrawerOptions={filteredDrawerOptions}
            smallScreen={smallScreen}
            logout={logout}
          />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open
          anchor="left"
        >
          <DrawerContent
            handleChange={handleChange}
            focusItem={focusItem}
            value={value}
            filteredDrawerOptions={filteredDrawerOptions}
            smallScreen={smallScreen}
            logout={logout}
          />
        </Drawer>
      </Hidden>
    </div>
  );
};

export default DrawerLeft;
