export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
export const NAME_REGEX = /^([a-zA-ZÀ-úÜü]+\s?)+$/;

export default values => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = 'Complete el nombre';
  } else if (!NAME_REGEX.test(values.firstName)) {
    errors.firstName = 'El nombre debe ser un nombre válido';
  }

  if (!values.lastName) {
    errors.lastName = 'Complete el apellido';
  } else if (!NAME_REGEX.test(values.lastName)) {
    errors.lastName = 'El apellido debe ser un apellido válido';
  }

  if (!values.email) {
    errors.email = 'Complete el email';
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = 'El mail debe ser un mail válido';
  }

  return errors;
};
