import React, { memo } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import moment from 'moment';
import { TextInput } from './TextInput';

function DatePicker({ label, value, onChange, error, disablePast, disableFuture, disabled }) {
  const classes = useStyles();
  return disabled ? (
    <TextInput disabled label={label} value={moment(value).format('L')} />
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        label={label}
        format="dd/MM/yyyy"
        value={value}
        InputProps={{
          readOnly: disabled,
          disableUnderline: disabled,
        }}
        InputAdornmentProps={{ position: 'end' }}
        onChange={(date, value) => onChange(date)}
        invalidDateMessage="Formato de fecha inválido"
        minDateMessage="Fecha inválida"
        maxDateMessage="Fecha inválida"
        disableFuture={disableFuture}
        disablePast={disablePast}
        className={classes.datePicker}
        error={!!error}
        helperText={error}
        style={{ margin: '10px 0' }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default memo(DatePicker);
