export const ROLE_REGEX = /^([a-zA-Z]+?)+$/;

export default values => {
  let errors = {};

  if (!values.role) {
    errors.role = 'Complete el nombre del rol';
  } else if (values.role.length < 2 || values.role.length > 20) {
    errors.role = 'El nombre debe contener entre 2 y 20 letras';
  } else if (!ROLE_REGEX.test(values.role)) {
    errors.role = 'El nombre debe contener solo letras';
  }

  return errors;
};
