import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { get, handleResponse, swal500 } from '../../../utils/network';

const useGetPermissions = (permissionsMapper, updatePermissions) => {
  const history = useHistory();
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [allPermissions, setAllPermissions] = useState([]);

  const getPermissions = useCallback(() => {
    setLoadingPermissions(true);
    get('permissions')
      .then(res => handleResponse(res, { history }))
      .then(res => setAllPermissions(res.message))
      .catch(e => swal500(e))
      .finally(() => setLoadingPermissions(false));
  }, [history]);

  useEffect(() => {
    const buildPermissions = () => {
      return allPermissions
        .map(permission => {
          if (!permission.category) return { ...permission, category: 'Others' };
          return permission;
        })
        .map(permission => ({
          ...permission,
          permissions: permission.permissions.map(p => permissionsMapper(p)),
        }));
    };

    if (allPermissions.length) updatePermissions(buildPermissions());
  }, [allPermissions, permissionsMapper, updatePermissions]);

  return {
    loadingPermissions,
    setLoadingPermissions,
    allPermissions,
    setAllPermissions,
    getPermissions,
  };
};

export default useGetPermissions;
