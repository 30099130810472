import React from 'react';
import { Paper } from '@material-ui/core';

const Surface = ({ children, className }) => {
  return (
    <Paper style={{ boxShadow: '0 8px 16px 0 rgb(0 0 0 / 10%)' }} className={className}>
      {children}
    </Paper>
  );
};

export default Surface;
